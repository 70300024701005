import React, { useState, useEffect } from 'react'
import './GetQuoteFormNew.scss'
import axios from 'axios'

export default function GetQuoteFormNew(props) {

    const [firstName, setFirstName] = useState ('');
    const [lastName, setLastName] = useState ('');
    const [mobileNumber, setMobileNumber] = useState ('');
    const [email, setEmail] = useState ('');
    const [from, setFrom] = useState ('');
    const [to, setTo] = useState ('');
    const [itemName, setItemName] = useState('');
    const [quantity, setQuantity] = useState ('');
    const [weight, setWeight] = useState('');
    // const [width, setWidth] = useState('');
    // const [length, setLength] = useState('');
    // const [height, setHeight] = useState('');
    const [hscode, setHsCode] = useState('');
    const [cbm, setCbm] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(firstName, lastName, mobileNumber, email, from, to, itemName, weight, width, length, height);
        const data = {
            FirstName:firstName,
            LastName:lastName,
            MobileNumber:mobileNumber,
            Email:email,
            From:from,
            To:to,
            ItemName:itemName,
            Quantity:quantity,
            Weight:weight,
            // Width:width,
            // Length:length,
            // Height:height,
            HsCode:hscode,
            Cbm:cbm
        }
        axios.post('https://sheet.best/api/sheets/1dd9534b-92ed-41cd-a269-e160880acb18', data).then((response)=>{
            console.log(response);
            setFirstName('');
            setLastName('');
            setMobileNumber('');
            setEmail('');
            setFrom('');
            setTo('');
            setItemName('');
            setQuantity('');
            setWeight('');
            // setWidth('');
            // setLength('');
            // setHeight('');
            setHsCode('');
            setCbm('');
        })
    }

    useEffect(() => {
        document.body.classList.add('no-scroll');
        return () => {
          document.body.classList.remove('no-scroll');
        };
      }, []);


  return (
    <div className='getquoteform-container'>
        <div className='card-fram'>
            <div className='card-title'>GET QUOTE</div>
            <form className='card-body' onSubmit={handleSubmit}>

                <div className='customer-detail'>
                    <div className='customer-information'><h1>Customer Details</h1></div>
                    <div className='customer-input-feilds'>
                        <div className='customer-detail-input'>
                            <label>First Name</label>
                            <input type="text" name='from' required 
                            onChange={(e) => setFirstName(e.target.value)} value={firstName}/>
                        </div>

                        <div className='customer-detail-input'>
                            <label>Last Name</label>
                            <input type="text" name='to' required 
                            onChange={(e)=>setLastName(e.target.value)} value={lastName}/>
                        </div>

                        <div className='customer-detail-input'>
                            <label>Mobile Number</label>
                            <input type="text" name='to' required 
                            onChange={(e)=>setMobileNumber(e.target.value)} value={mobileNumber}/>
                        </div>

                        <div className='customer-detail-input'>
                            <label>Email Address</label>
                            <input type="text" name='to' required 
                            onChange={(e)=>setEmail(e.target.value)} value={email}/>
                        </div>
                    </div>
                </div>

                <div className='countries-input'>
                    <div className='requested-feilds'><h1>Required Fields (Countries)</h1></div>
                    <div className='request-input-feilds'>
                        <div className='request-input-feilds-from'>
                            <label>From / POL</label>
                            <input type="text" name='from' required 
                            onChange={(e)=>setFrom(e.target.value)} value={from}/>
                        </div>

                        <div className='request-input-feilds-to'>
                            <label>To / POD</label>
                            <input type="text" name='to' required style={{paddingLeft:'10px'}}
                            onChange={(e)=>setTo(e.target.value)} value={to}/> 
                        </div>
                    </div>
                </div>

                <div className='package-detail'>
                <div className='package-information'><h1>Package Details</h1></div>
                    <div className='package-input-feilds'>
                        <div className='package-detail-input'>
                            <label>Item Name</label>
                            <input type="text" name='from' required 
                            onChange={(e)=>setItemName(e.target.value)} value={itemName}/>
                        </div>

                        <div className='package-detail-input'>
                            <label>Quantity</label>
                            <input type="text" name='to' required 
                            onChange={(e)=>setQuantity(e.target.value)} value={quantity}/>    
                        </div>

                        <div className='package-detail-input'>
                            <label>Weight (KG)</label>
                            <input type="text" name='to' required 
                            onChange={(e)=>setWeight(e.target.value)} value={weight}/>
                        </div>

                        {/* <div className='package-detail-input'>
                            <label>Length (CM)</label>
                            <input type="text" name='to' required 
                            onChange={(e)=>setLength(e.target.value)} value={length}/>
                        </div> */}

                        {/* <div className='package-detail-input'>
                            <label>Width(CM)</label>
                            <input type="text" name='to' required 
                            onChange={(e)=>setWidth(e.target.value)} value={width}/>
                        </div> */}

                        {/* <div className='package-detail-input'>
                            <label>Height (CM)</label>
                            <input type="text" name='to' required 
                            onChange={(e)=>setHeight(e.target.value)} value={height}/>
                        </div> */}

                        <div className='package-detail-input'>
                            <label>CBM</label>
                            <input type="text" name='cbm' 
                            onChange={(e)=>setCbm(e.target.value)} value={cbm}/>
                        </div>

                    </div>
                </div>

                <div className='optional-detail'>
                <div className='optional-information'><h1>Optional</h1></div>
                    <div className='optional-input-feilds'>
                        <div className='optional-detail-feilds'>
                            <label>HS Code</label>
                            <input type="text" name='hscode' 
                            onChange={(e)=>setHsCode(e.target.value)} value={hscode}/>
                        </div>

                        {/* <div className='optional-detail-feilds'>
                            <label>CBM</label>
                            <input type="text" name='cbm' 
                            onChange={(e)=>setCbm(e.target.value)} value={cbm}/>
                        </div> */}
                    </div>
                </div>

                <div className='quote-footer'>
                    <button className='quote-submit'>SUBMIT</button>
                    <div className='quote-cancel' onClick={() => props.setDisplayPopUp(false)}>CANCEL</div>
                </div>
            </form>
        </div>
    </div>
  )
}
