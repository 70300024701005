import React from 'react'
import './OurImportance.scss'
import { Zoom } from 'react-reveal'
import bookOrder from  '../../images/importance/bookOrder.png'
import exploreSolutions from '../../images/importance/exploreSolutions.png'
import getQuote from '../../images/importance/getQuote.png'
import ReceiveShipment from '../../images/importance/ReceiveShipment.png'
import trackShipment from '../../images/importance/trackShipment.png'

export default function OurImportance() {
  return (
    <div className='ouriimportance-container'>
        <Zoom duration={3000}>
            <div className='card-body'>
                <div className='importance-header'>How We Can Help</div>
                <div className='importance-body'>

                    <div className='card-fram'>
                        <div className='image'><img src={exploreSolutions} alt='graphic'/></div>
                        <div className='title'>Explore Solutions</div>
                        <div className='detail'>View our transportation and digital logistics products across air, ocean, trucking, warehousing, and more.</div>
                    </div>

                    <div className='card-fram'>
                        <div className='image'><img src={getQuote} alt='graphic'/></div>
                        <div className='title'>Get a Quote</div>
                        <div className='detail'>Speak to one of our logistics professionals to find a solution that works for you and your business.</div>
                    </div>

                    <div className='card-fram'>
                        <div className='image'><img src={bookOrder} alt='graphic'/></div>
                        <div className='title'>Book Your Order</div>
                        <div className='detail'>Book your customized order based on your supply chain needs.</div>
                    </div>

                    <div className='card-fram'>
                        <div className='image'><img src={trackShipment} alt='graphic'/></div>
                        <div className='title'>Track a Shipment</div>
                        <div className='detail'>Access real-time tracking updates and information throughout your shipment's journey.</div>
                    </div>

                    <div className='card-fram'>
                        <div className='image'><img src={ReceiveShipment} alt='graphic'/></div>
                        <div className='title'>Receive Your Shipment</div>
                        <div className='detail'>Get your freight and review processes to improve your next shipment.</div>
                    </div>

                </div>
            </div>
        </Zoom>
    </div>
  )
}
