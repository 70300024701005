import React from 'react'
import './AboutNew.scss'
import map from '../../images/aboutImage.png'
import {HiOutlineCheckBadge} from 'react-icons/hi2'
import {SiLinkerd} from 'react-icons/si'
import {ImLoop} from 'react-icons/im'
import  Fade  from 'react-reveal/Fade'

export default function AboutNew() {
  return (
    <div className='about-container' id="about-container">
        <div className='top'>

            <Fade bottom distance='20%' duration={2000}>
            <div className='left'>
                <img src={map} alt="" />
            </div>
            </Fade>

            <Fade bottom distance='20%' duration={2000}>
            <div className='right'>
                <div className='top'><h1>ABOUT US</h1></div>
                <div className='body'><p>Welcome to Metro Express, a China and Sri Lanka logistics company with over 10 years of experience in handling cargo. We offer a wide range of services including sea, air cargo, custom brokerage, quality inspection, and more. Our team of professionals is dedicated to providing safe and timely delivery of your goods. Contact us today for reliable and efficient logistics solutions.</p></div>
            </div>
            </Fade>
        </div>

        <div className='bottom'>
            <div className='card-section'>

                <Fade bottom distance='20%' duration={2000}>
                <div className="card-fram">
                    <div className="card-header">
                        <div className="nameOfTheCard">
                            <p>Our Mission</p>
                        </div>
                        <div className="icon">
                            <HiOutlineCheckBadge color='#006FB4'/>
                        </div>
                    </div>
                    <div className="card-body">
                        <p>
                            To provide efficient, effective and reliable logistics solutions tailor-made to our clients'
                            unique requirements with a team of high calibre professionals committed to the highest standards of excellence while delivering profitable business growth to achieve 100% customer satisfaction through high performance and the highest degree of professionalism and integrity.
                        </p>
                    </div>
                </div>
                </Fade>

                <Fade bottom distance='20%' duration={2000}>
                <div className="card-fram">
                    <div className="card-header">
                        <div className="nameOfTheCard">
                            <p>Our Vision</p>
                        </div>
                        <div className="icon">
                            <SiLinkerd color='#006FB4'/>
                        </div>
                    </div>
                    <div className="card-body">
                        <p>
                        To be a premier solution provider in cargo forwarding and logistics solutions in the region with an 
                        unwavering commitment to efficiency, productivity, and speed of execution.
                        </p>
                    </div>
                </div>
                </Fade>

                <Fade bottom distance='20%' duration={2000}>
                <div className="card-fram">
                    <div className="card-header">
                        <div className="nameOfTheCard">
                            <p>Why Choose Us</p>
                        </div>
                        <div className="icon">
                            <ImLoop color='#006FB4'/>
                        </div>
                    </div>
                    <div className="card-body">
                        <p>
                            With over 21 years of experience in the industry, employing experienced staff, 
                            providing end-to-end international transportation solutions with innovation and transparency makes MSA the best choice for all your logistics requirements.
                        </p>
                    </div>
                </div>
                </Fade>

            </div>
        </div>
    </div>
  )
}
