import React, { useEffect, useState } from 'react';
import './HomeNew.scss'
import { BsCartFill, BsFillSendFill } from 'react-icons/bs';
import { AiFillFlag } from 'react-icons/ai';
import GetQuoteFormNew from './GetQuoteForm/GetQuoteFormNew';
// import nameImage from '../../images/frant-name.png'
import logo from '../../images/logo.png'
import  Fade  from 'react-reveal/Fade'

export default function HomeNew() {

    const [clientCount, setClientCount] = useState(0);
    const [workersCount, setWorkersCount] = useState(0);
    const [branchesCount, setBranchesCount] = useState(0);
    const [displayPopUp, setDisplayPopUp] = useState(false);

    const targetClientCount = 150;
    const targetWorkersCount = 13;
    const targetBranchesCount = 4;
  
    useEffect(() => {
      const countInterval = setInterval(() => {
        if (clientCount < targetClientCount) {
          setClientCount((prevCount) => prevCount + 1);
        }
  
        if (workersCount < targetWorkersCount) {
          setWorkersCount((prevCount) => prevCount + 1);
        }
  
        if (branchesCount < targetBranchesCount) {
          setBranchesCount((prevCount) => prevCount + 1);
        }
      }, 10); // Adjust the interval duration as desired
  
      return () => {
        clearInterval(countInterval);
      };
    }, [clientCount, workersCount, branchesCount, targetClientCount, targetWorkersCount, targetBranchesCount]);
  
    function showCode(){
      setDisplayPopUp(true);
    }
  return (
    <>
    {
      displayPopUp ? <GetQuoteFormNew setDisplayPopUp={setDisplayPopUp} /> : null
    }
    <div className='homeNew-container' id='home'>
      <div className="home-top-section">
        <div className="front-image-and-details">
            <div className="name">
              <img src={logo} alt="nameImage" />
              <h1>THE PERFECT LOGISTIC</h1>
            </div>
            <div className="detail">
              <div className="detail-left">
                <div className="client">
                    <div className="number">
                    <h1>{clientCount}</h1>
                    </div>
                    <hr />
                    <div className="title">
                    <p>CLIENTS</p>
                    </div>
                </div>
                <div className="workers">
                    <div className="number">
                    <h1>{workersCount}</h1>
                    </div>
                    <hr />
                    <div className="title">
                    <p>WORKERS</p>
                    </div>
                </div>
                <div className="branches">
                    <div className="number">
                    <h1>{branchesCount}</h1>
                    </div>
                    <hr />
                    <div className="title">
                    <p>BRANCHES</p>
                    </div>
                </div>
              </div>
              <div className="detail-right">
                <button onClick={showCode}>GET QUOTE</button>
              </div>
            </div>
        </div>
      </div>

      <div className='home-footer-section'>

        <Fade bottom duration={2000}>
        <div className="card-fram">
            <div className="card-header">
                <div className="nameOfTheCard">
                    <p>Warehouse</p>
                </div>
                <div className="icon">
                    <BsCartFill color='white'/>
                </div>
            </div>
            <div className="card-body">
                <p>
                To provide efficient, effective, and reliable logistics solutions tailor-made to our client's unique requirements with a team of high-caliber professionals committed to the highest standards.
                </p>
            </div>
        </div>
        </Fade>

        <Fade bottom duration={2000}>
        <div className="card-fram">
            <div className="card-header">
                <div className="nameOfTheCard">
                    <p>Delivery</p>
                </div>
                <div className="icon">
                <BsFillSendFill color='white'/>
                </div>
            </div>
            <div className="card-body">
                <p>
                To be a premier solution provider in cargo forwarding and logistics by delivering sustainable value to all our stakeholders through innovative and customized solutions.
                </p>
            </div>
        </div>
        </Fade>

        <Fade bottom duration={2000}>
        <div className="card-fram">
            <div className="card-header">
                <div className="nameOfTheCard">
                    <p>Shipping</p>
                </div>
                <div className="icon">
                    <AiFillFlag color='white'/>
                </div>
            </div>
            <div className="card-body">
                <p>
                With over 21 years of experience in the industry, employing experienced staff, and providing end-to-end international transportation solutions with innovation and transparency makes MSA the best choice for all your logistics requirements.
                </p>
            </div>
        </div>
        </Fade>

      </div>
    </div>
    </>
  )
}
