import React, { useRef, useState, useEffect } from 'react';
import './ContactForm.scss'
import image from "../../../images/contact.jpg"
import {BsFillSendFill, BsWechat} from 'react-icons/bs'
import emailjs from '@emailjs/browser';
import {FaFacebookSquare, FaLinkedin, FaInstagramSquare, FaWhatsapp} from 'react-icons/fa'
// import {AiFillCloseCircle}  from 'react-icons/ai'

const Result =() => {
    return (
      <p style={{color:"white"}}>Your massage has been succsessfully sent. I will contact you soon.</p>
    )
}

export default function ContactForm(props) {
    const [result, showResult] = useState (false);
    const form = useRef();
  
    const sendEmail = (e) => {
      e.preventDefault();
  
      // emailjs.sendForm('service_guhubad', 'template_uja5vd8', form.current, '6xpgg2w6Z_4Ux7VNq')
      emailjs.sendForm('service_5f48hst', 'template_fqhjwjo', form.current, '_pv4IQu8twoU6-Nrx')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
  
        e.target.reset();
        showResult (true);
    };
  
    setTimeout(()=>{
      showResult(false);
    },5000)

    useEffect(() => {
      document.body.classList.add('no-scroll');
      return () => {
        document.body.classList.remove('no-scroll');
      };
    }, []);


  return (
    <div className='ContactMe-container'>
    <div className='contactMe-parent'>
      <div className='contactForm-left'>
      {/* <div className='close-btn' onClick={() => props.setDisplayContact(false)}><AiFillCloseCircle size={25} color='red'/></div> */}
        <h1>Get In Touch</h1>
        <div className='colz'>
          <div className='colz-icon'>
            
            <a href="https://web.facebook.com/MetroCMB" rel="noreferrer" target="_blank">
              <FaFacebookSquare />
            </a>

            <a href="https://www.linkedin.com/company/metro-express-colombo-limited/" rel="noreferrer" target="_blank">
              <FaLinkedin />
            </a>

            <a href="https://www.instagram.com/metro.express/" rel="noreferrer" target="_blank">
              <FaInstagramSquare />
            </a>

            <a href='#'>
              <BsWechat />
            </a>

            <a href="https://wa.me/message/N3YIDUVPY2BFB1" rel="noreferrer" target="_blank">
              <FaWhatsapp />
            </a>

          </div>
        </div>
        <div>{result ? <Result /> : null}</div>
        <h6>Send Your Email Here</h6>
        <div className='image'><img src={image} alt="" /></div>
      </div>
      <div className='contactForm-right'>
        <form className='form-top' ref={form} onSubmit={sendEmail}>
          <div className='input-field'>
            <label>Name</label>
            <input type="text" name='from_name' required/>
          </div>

          <div className='input-field'>
            <label>Email</label>
            <input type="email" name='email' required/>
          </div>

          <div className='input-field'>
            <label>Message</label>
            <textarea id="message" name="message" rows={5} required></textarea>
          </div>
          <div className='botom-section' style={{display:'flex'}}>
            <button className='submit-btn'>Send <BsFillSendFill style={{marginLeft:'5px'}}/></button>
            <button className='submit-btn' style={{marginLeft:'10px'}} onClick={() => props.setDisplayContact(false)}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>  
  )
}
