import React from 'react'
import './ServiceNew2.scss'
import storage from '../../images/service/storage.jpg'
import logistics from '../../images/service/logistic.jpg'
import cargo from '../../images/service/cargo.jpg'
import tracking from '../../images/service/trucking.jpg'
import packing from '../../images/service/packing.jpg'
import warehouseing from '../../images/service/warehousing.jpg'
import  Fade  from 'react-reveal/Fade'

export default function ServiceNew() {
  return (
    <div className='serviceNew-container' id='Service'>
        <Fade bottom duration={2000}><div className='service-header'><h1>OUR SERVICES</h1></div></Fade>
        <div className='card-section'> 

            <Fade bottom duration={2000}>
            <div className='card-fram'>
                <img src={storage} alt="" />
                <div className='introduction'>
                <h1>STORAGE</h1>
                    <p>Metro Express offers safe and flexible business storage solutions to meet your needs. Our state-of-the-art warehouse facilities are equipped with advanced security systems to ensure the safety of your inventory. We provide both short-term and long-term storage options, accompanied by personalized support from our team of professionals. Contact us today to learn more about our storage solutions and discover how we can assist you with all your logistics needs.</p>
                </div>
            </div>
            </Fade>

            <Fade bottom duration={2000}>
            <div className='card-fram'>
                <img src={logistics} alt="" />
                <div className='introduction'>
                <h1>LOGISTICS</h1>
                    <p>At Metro Express, we provide comprehensive logistics services to meet your business needs. Our services include sea cargo, air cargo, consolidated shipments, personal cargo, trucking, inland transportation, custom brokerage, quality inspection, trading, sourcing, and more. Our team of professionals is dedicated to providing safe and efficient logistics solutions, with a focus on customer satisfaction. We use the latest technology and equipment to ensure that your cargo is handled with care from start to finish. With our extensive network of partners and experience in handling complex logistics requirements, we can provide cost-effective solutions for all your logistics needs.</p>
                </div>
            </div>
            </Fade>

            <Fade bottom duration={2000}>
            <div className='card-fram'>
                <img src={cargo} alt="" />
                <div className='introduction'>
                <h1>CARGO</h1>
                    <p>Cargo refers to goods or products that are transported from one location to another by various means such as sea, air, or land. Metro Express offers reliable and efficient cargo services. Cargo can be shipped in containers, pallets, or other packaging formats depending on its nature and size. The transportation of cargo requires careful planning, organization, and logistics management to ensure timely delivery, proper handling, and the security of the goods throughout the journey. Contact us today to learn more about our cargo services and how we can assist you with all your logistics needs.</p>
                </div>
            </div>
            </Fade>

            <Fade bottom duration={2000}>
            <div className='card-fram'>
                <img src={tracking} alt="" />
                <div className='introduction'>
                <h1>TRUCKING</h1>
                    <p>Metro Express offers reliable and efficient trucking and inland transportation services to meet your business needs. Our team of professionals is committed to providing safe and timely transportation of your cargo, with a focus on customer satisfaction. We use the latest technology and equipment to ensure that your cargo is handled with care from start to finish. With our extensive network of partners and experience in handling complex logistics requirements, we can provide cost-effective solutions for all your trucking and transportation needs. Contact us today to learn more about our services and how we can assist you with all your logistics needs.</p>
                </div>
            </div>
            </Fade>

            <Fade bottom duration={2000}>
            <div className='card-fram'>
                <img src={packing} alt="" />
                <div className='introduction'>
                <h1>PACKAGING</h1>
                    <p>At Metro Express, we provide professional and reliable packing services to ensure that your cargo is protected during transportation. Our team of experts uses high-quality packing materials and techniques to ensure that your goods are safely and securely packed. We offer a range of packing options to meet your needs, including palletizing, crating, and more. With our focus on customer satisfaction and attention to detail, you can trust us to handle your packing needs with care. Contact us today to learn more about our packing services and how we can assist you with all your logistics needs.</p>
                </div>
            </div>
            </Fade>

            <Fade bottom duration={2000}>
            <div className='card-fram'>
                <img src={warehouseing} alt="" />
                <div className='introduction'>
                <h1>WAREHOUSING</h1>
                    <p>At Metro Express, we provide professional and reliable packing services to ensure your goods are protected during storage. Warehousing is the storage of goods in specialized facilities known as warehouses. It includes receiving, organizing and protecting goods until they are ready for delivery or transport. Warehouses play an important role in the supply chain by ensuring inventory is kept safe and managed efficiently. Contact us today to learn more about our freight and how we can assist you with all your logistics needs.
                    <br />
                    <p style={{fontWeight:'bold'}}>Warehouse locations</p>
                    <li>Guangzhou</li>
                    <li>Yiwu</li>
                    </p>
                </div>
            </div>
            </Fade>

        </div>
    </div>
  )
}
