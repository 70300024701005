import React from 'react'
import './NavigationNew.scss'
import logo from '../../images/logo2.png'
import {FaBars, FaTimes} from 'react-icons/fa'

export default function Navigation() {
  return (
    <div className='navigation-container'>
      <nav>
        <input type="checkbox" id='check' className='check'/>
        <label for='check'>
          {/* <i className='fas fa-bars' id='btn'></i>
          <i className='fas fa-times' id='cancel'></i> */}
          <FaBars className='btn'/>
          <FaTimes className='cancel'/>
        </label>
        <div className='name'>
          <div className='logo'><a href="#home"><img src={logo} alt="logo" /></a></div>
          <h1><a href="#home">Metro Express</a></h1>
        </div>
        <ul>
          <li><a href="#home">Home</a></li>
          <li><a href="#about-container">About</a></li>
          <li><a href="#Service">Services</a></li>
          {/* <li><a href="#Package">Package</a></li> */}
          <li><a href="#ContactUs">Contact</a></li>
        </ul>
      </nav>
    </div>
  )
}