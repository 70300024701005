import React from 'react'
import './PackageNew.scss'
import globlePackage from "../../images/global-package.jpg"
import currierService from '../../images/currier-service.jpg'
// import domesticFree from '../../images/domestic-free.jpg'
import internationalShipping from '../../images/international-shipping.jpg'
import  Fade  from 'react-reveal/Fade'

export default function PackageNew() {
  return (
    <div className='packageNew-container' id='Package'>
        {/* <div className='package-header'><h1>Package</h1></div> */}
        <div className='packagecard-section'> 

            <Fade bottom distance='20%'>
            <div className='package-card-fram'>
                <div className='package-left'>
                    <div className="img1">
                        <img src={globlePackage} alt="" />
                    </div>
                </div>
                <div className='package-right'>
                    <h1>Global Package Tracking</h1>
                    <p>We offer reliable and efficient tracking services for your packages no matter where they are headed. We provide global coverage and use advanced technology to offer accurate and reliable tracking information to keep you informed every step of the way. With our package tracking service, you can have peace of mind knowing that your shipments are being monitored and tracked. Contact us today to learn more about our package tracking solutions and how we can assist you with all your logistics needs.</p>
                </div>
            </div>
            </Fade>

            <Fade bottom distance='20%'>
            <div className='package-card-fram'>
                <div className='package-left'>
                    <div className="img1">
                        <img src={currierService} alt="" />
                    </div>
                </div>
                <div className='package-right'>
                    <h1>Courier Service</h1>
                    <p>We offer fast and reliable courier services for your urgent and time-sensitive packages. Our dedicated team of professionals ensures that your packages are delivered safely and on time. Our network of partners and advanced technology allow us to provide efficient and reliable courier services. We offer a range of options to suit your needs, including same-day delivery, next-day delivery, and express delivery. With our courier service, you can have peace of mind knowing that your packages are being delivered quickly and securely. Contact us today to learn more about our courier solutions and how we can assist you with all your logistics needs.</p>
                </div>
            </div>
            </Fade>

            {/* <Fade bottom distance='20%'>
            <div className='package-card-fram'>
                <div className='package-left'>
                    <div className="img1">
                        <img src={domesticFree} alt="" />
                    </div>
                </div>
                <div className='package-right'>
                    <h1>Domestic Free Deliver</h1>
                    <p>We offer fast and reliable domestic shipping services across the country with no delivery charges. Our dedicated team of professionals ensures that your packages are delivered safely and on time. With our user-friendly interface, you can easily track your shipments in real-time and receive updates about the delivery status. Our network of partners and advanced technology allows us to provide efficient and reliable domestic delivery services. With our domestic free delivery service, you can have peace of mind knowing that your packages are being delivered quickly and without any additional fees. Contact us today to learn more about our domestic delivery solutions and how we can assist you with all your logistics needs.</p>
                </div>
            </div>
            </Fade> */}

            <Fade bottom distance='20%'>
            <div className='package-card-fram'>
                <div className='package-left'>
                    <div className="img1">
                        <img src={internationalShipping} alt="" />
                    </div>
                </div>
                <div className='package-right'>
                    <h1>International Shipping</h1>
                    <p>We offer reliable and efficient international shipping services to over [number] countries worldwide. Our dedicated team of professionals ensures that your packages are delivered safely and on time. With our user-friendly interface, you can easily track your shipments in real-time and receive updates about the delivery status. Our network of partners and advanced technology allows us to provide efficient and reliable international shipping services. We offer a range of options to suit your needs, including air freight, sea freight, and express shipping. With our international shipping service, you can have peace of mind knowing that your packages are being delivered to their destination quickly and securely. Contact us today to learn more about our international shipping solutions and how we can assist you with all your logistics needs.</p>
                </div>
            </div>
            </Fade>

        </div>
    </div>
  )
}
