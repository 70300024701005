import React, { useState } from 'react';
import './ContactUs.scss'
import contactImage from '../../images/aireplane.png'
import {FaFacebookSquare, FaLinkedin, FaInstagramSquare, FaWhatsapp} from 'react-icons/fa'
import {BsWechat} from 'react-icons/bs'
import ContactForm from './ContactForm/ContactForm'
import  Fade  from 'react-reveal/Fade'
 
export default function ContactUs() {

    const [displayContact, setDisplayContact] = useState(false);

    function showCode() {
        setDisplayContact(true);
      }
      

  return (
    <div className='contactUs-container' id="ContactUs">
    {displayContact ? <ContactForm setDisplayContact={setDisplayContact} /> : null}
        <Fade bottom distance='20%'>
        <div className='contactUs-top'>
            <div className='contactUs-top-left'><img src={contactImage} alt="contactImg" /></div>
            <div className='contactUs-top-right'>
                <div className='contactUs-top-right-header'><p>CONTACT US</p></div>
                <div className='contactUs-top-right-body'><p>Ready to get started? Contact us today to learn more about our logistics solutions and how we can assist you with all your shipping needs. Our team of experts is here to help answer any questions you may have and provide you with a customized solution tailored to your unique requirements. Whether you need domestic delivery, international shipping, or courier services, we have the expertise and resources to get your packages to their destination quickly and safely. Contact us now to speak with a representative and experience the Metro Express difference.</p></div>
                <button onClick={showCode}>Contact US</button>
            </div>
        </div>
        </Fade>

        <Fade bottom distance='20%'>
        <div className='contactUs-bottom'>
            <div className='conatct-left'>
                <h1>METRO EXPRESS</h1>
                <p>THE PERFECT LOGISTICS</p>
                <div className='colz-icon'>
                    <a href="https://web.facebook.com/MetroCMB" rel="noreferrer" target="_blank">
                        <FaFacebookSquare />
                    </a>

                    <a href="https://www.linkedin.com/company/metro-express-colombo-limited/" rel="noreferrer" target="_blank">
                        <FaLinkedin />
                    </a>

                    <a href="https://www.instagram.com/metro.express/" rel="noreferrer" target="_blank">
                        <FaInstagramSquare />
                    </a>

                    <a href='#'>
                        <BsWechat />
                    </a>

                    <a href="https://wa.me/message/N3YIDUVPY2BFB1" rel="noreferrer" target="_blank">
                        <FaWhatsapp />
                    </a>
                </div>
                <div className='contact-detail'>

                    <div className='detail-left'>
                        <p>Phone</p>
                        <p>Email</p>
                        <p>WhatsApp </p>
                    </div>
                    <div className='detail-right'>
                        <p>+94 78 555 7773</p>
                        <p>info@metrocmb.com</p>
                        <p>+94 78 555 7773</p>
                    </div>
                </div>
            </div>

            <div className='contact-center'>
                <div className='contact-center-top'>
                    <div className='centerPart-left'>
                        <h1>Useful Links</h1>
                        <a href="#home">Home</a>
                        <a href="#about-container">About</a>
                        <a href="#Service">Services</a>
                        {/* <a href="#Package">Package</a> */}
                        <a href="#ContactUs">Contact</a>
                    </div>
                    <div className='centerPart-center'>
                        <h1>Useful Links</h1>
                        <a href=''>Ocean Exports</a>
                        <a href=''>Ocean Import</a>
                        <a href=''>Air Export</a>
                        <a href=''>Air Import</a>
                    </div>
                    <div className='centerPart-right'>
                        <h1>Address</h1>
                        <p> 712/3 Baseline Road, 
                            Dematagoda, 
                            Colombo 9 
                            (Next to Orion City)
                            </p>
                    </div>
                </div>
            </div>

            <div className='contact-right'>
                <div className='map-fram'>
                    <div className='google-map'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d414.79507225980007!2d79.87852677026302!3d6.940146276596333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2slk!4v1688725976242!5m2!1sen!2slk" 
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade"
                    title='map'>
                    </iframe>
                    </div>
                </div>
            </div>
            
        </div>
        </Fade>
        <div className='conatct-center-bottom' style={{backgroundColor:'#101721', width:'100%'}}>
            <p style={{color:'white'}}>© Copyright Metro Express. All Rights Reserved</p>
        </div>
    </div>
  )
}
