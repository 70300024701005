import './App.css';
import NavigationNew from './MetroExpressContainer/Navigation/NavigationNew';
import HomeNew from './MetroExpressContainer/Home/HomeNew';
import AboutNew from './MetroExpressContainer/About/AboutNew';
import PackageNew from './MetroExpressContainer/Packages/PackageNew';
import ContactUs from './MetroExpressContainer/ContactPage/ContactUs';
import OurImportance from './MetroExpressContainer/OurImportance/OurImportance';
import ServiceNew2 from './MetroExpressContainer/Service/ServiceNe2w';
// import GetQuoteFormNew from './MetroExpressContainer/Home/GetQuoteForm/GetQuoteFormNew';

function App() {
  return (
    <div className="App">
      <NavigationNew />
      <HomeNew />
      <OurImportance />
      <AboutNew />
      <ServiceNew2 />
      <PackageNew />
      <ContactUs />
      {/* <GetQuoteFormNew /> */}
    </div>
  );
}

export default App;
